body {
  margin: 0;
  color: #000 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif, Microsoft Yahei, Hiragino Sans GB, Heiti SC, WenQuanYi Micro Hei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media all and (min-width: 1680px) and (max-width:1919px) {

  .ecological-cooperation .cooperation-banner .container,
  .cooperationBook .cooperationBook-banner .cooperationBook-banner-content {
    left: 260px !important;
    right: 260px !important;
  }
}

@media all and (min-width: 1600px) and (max-width:1919px) {

  .head .h-container {
    margin: 0 260px !important;
  }

  .banner .banner-content {
    left: 260px !important;
    right: 260px !important;
    bottom: 80px !important;
  }

  /* 首页 */
  .product,
  .function .func-container,
  .ecological .ecological-content {
    margin: 0 260px !important;
  }

  .case,
  .experience,
  .experience-enter {
    margin: 72px 260px 0 260px !important;
  }

  .cooperation {
    margin: 72px 260px !important;
  }

  .data-banner .data-banner-container {
    left: 260px !important;
    right: 260px !important;
  }

  .data-problem,
  .data-scene {
    margin: 72px 260px !important;
  }

  .data-worth {
    margin: 0 260px !important;
  }

  .data-scene .scene-container div span {
    margin-right: 110px !important;
  }

  .data-scene .scene-container div p {
    padding-left: 528px !important;
  }

  .ecological-cooperation .cooperation-type,
  .ecological-cooperation .cooperation-equities,
  .cooperationBook .cooperationBook-container,
  .about .about-introduce {
    margin: 72px 260px !important;
  }

  .ecological-cooperation .cooperation-process {
    margin: 0 260px !important;
  }

  .ecological-cooperation .cooperation-process .process-container div::before {
    left: -60px !important;
  }

  .cooperationBook .cooperationBook-banner .cooperationBook-banner-content p,
  .ecological-cooperation .cooperation-banner .container p {
    text-align: justify;
    padding: 0 90px !important;
  }

  .document .document-container {
    margin: 48px 260px 72px 260px !important;
  }

  .document .document-container .nav-content .ant-layout-has-sider .arrow-up {
    right: 160px !important;
  }

  .about .about-contact {
    margin: 0 260px 72px 260px !important;
  }

  .footer {
    padding: 64px 260px 0 260px !important;
  }

}

@media all and (min-width: 1600px) and (max-width:1679px) {
  .case .ant-tabs>.ant-tabs-nav {
    width: 400px !important;
  }

  .data-scene .scene-container div span {
    margin-right: 60px !important;
  }

  .data-scene .scene-container div p {
    padding-left: 579px !important;
  }

  .ecological-cooperation .cooperation-banner .container,
  .cooperationBook .cooperationBook-banner .cooperationBook-banner-content {
    left: 260px !important;
    right: 260px !important;
  }

  .ecological-cooperation .cooperation-equities .equities-container div {
    width: auto !important;
    height: auto !important;
  }

  .about .about-introduce .introduce-content div p {
    width: 600px !important;
  }

  .about .about-contact .contact-container .line-title div {
    margin-left: 40px !important;
  }

  .footer .footer-container div p {
    grid-template-columns: 370px 350px 370px !important;
  }
}

@media all and (min-width:1440px) and (max-width:1599px) {

  .head .h-container,
  .product,
  .function .func-container,
  .ecological .ecological-content,
  .data-worth,
  .case {
    margin: 0 180px !important;
  }

  .banner .banner-content {
    left: 180px !important;
    right: 180px !important;
    bottom: 50px !important;
  }

  .banner .banner-content .case,
  .experience,
  .experience-enter {
    margin: 72px 180px 0 180px !important;
  }

  .case .ant-tabs>.ant-tabs-nav {
    width: 400px !important;
  }

  .case .ant-tabs-tab-btn {
    font-size: 20px !important;
  }

  .case .ant-tabs-tabpane {
    width: 580px !important;
  }

  .case .case-content .case-btn span {
    margin-top: 10px !important;
  }

  .cooperation,
  .data-problem,
  .data-scene {
    margin: 72px 180px !important;
  }

  .ecological .ecological-content .ecological-right {
    width: 670px !important;
  }

  .data-banner .data-banner-container {
    left: 180px !important;
    right: 180px !important;
  }

  .data-scene .scene-container img {
    width: 680px !important;
    height: 480px !important;
  }

  .ecological-cooperation .cooperation-banner .container {
    left: 180px !important;
    right: 180px !important;
  }

  .ecological-cooperation .cooperation-banner .container p {
    padding: 0 135px !important;
    text-align: justify !important;
  }

  .ecological-cooperation .cooperation-type,
  .ecological-cooperation .cooperation-equities,
  .cooperationBook .cooperationBook-container,
  .about .about-introduce {
    margin: 72px 180px !important;
  }

  .ecological-cooperation .cooperation-process {
    margin: 0 180px !important;
  }

  .ecological-cooperation .cooperation-process .process-container div::before {
    width: 35px !important;
    left: -45px !important;
  }

  .cooperationBook .cooperationBook-banner .cooperationBook-banner-content {
    left: 180px !important;
    right: 180px !important;
  }

  .ecological-cooperation .cooperation-equities .equities-container div {
    width: auto !important;
    height: auto !important;
  }

  .document .document-container {
    margin: 48px 180px 72px 180px !important;
  }

  .about .about-banner .about-banner-content {
    left: 180px !important;
    right: 180px !important;
    bottom: 70px !important;
  }

  .about .about-contact {
    margin: 0 180px 72px 180px !important;
  }

  .about .about-contact .contact-container .line-title div {
    margin-left: 50px !important;
  }

  .about .about-introduce .introduce-content div p {
    width: 600px !important;
  }

  .footer {
    padding: 64px 180px 0 180px !important;
  }

  .footer .footer-container div p {
    grid-template-columns: 350px 350px 350px !important;
  }
}

@media all and (min-width:1366px) and (max-width:1439px) {

  .banner .banner-content,
  .data-banner .data-banner-container,
  .ecological-cooperation .cooperation-banner .container,
  .cooperationBook .cooperationBook-banner .cooperationBook-banner-content {
    left: 100px !important;
    right: 100px !important;
  }

  .case,
  .experience,
  .ecological .ecological-content,
  .product,
  .function .func-container,
  .experience-enter,
  .cooperation,
  .data-problem,
  .data-scene,
  .ecological-cooperation .cooperation-type,
  .ecological-cooperation .cooperation-process,
  .ecological-cooperation .cooperation-equities,
  .head .h-container,
  .data-worth,
  .cooperationBook .cooperationBook-container,
  .document .document-container,
  .about .about-introduce,
  .about .about-contact {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .banner .banner-content {
    bottom: 10px !important;
  }

  .case .ant-tabs>.ant-tabs-nav {
    width: 435px !important;
  }

  .case .ant-tabs-tab-btn {
    font-size: 24px !important;
  }

  .about .about-banner .about-banner-content {
    left: 100px !important;
    right: 100px !important;
    bottom: 65px !important;
  }

  .footer {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}

@media all and (min-width:1280px) and (max-width:1365px) {

  .head .h-container,
  .product,
  .data-problem,
  .function .func-container,
  .case,
  .experience,
  .ecological .ecological-content,
  .experience-enter,
  .cooperation,
  .data-worth,
  .data-scene,
  .ecological-cooperation .cooperation-type,
  .ecological-cooperation .cooperation-process,
  .ecological-cooperation .cooperation-equities,
  .cooperationBook .cooperationBook-container,
  .document .document-container,
  .about .about-introduce,
  .about .about-contact {
    margin-left: 150px !important;
    margin-right: 150px !important;
  }


  .banner .banner-content,
  .data-banner .data-banner-container,
  .ecological-cooperation .cooperation-banner .container,
  .cooperationBook .cooperationBook-banner .cooperationBook-banner-content,
  .about .about-banner .about-banner-content {
    left: 150px !important;
    right: 150px !important;
    bottom: 50px !important;
  }

  .banner .banner-content .banner-title {
    font-size: 34px !important;
  }

  .banner .banner-content .banner-tips {
    font-size: 12px !important;
  }

  .banner .banner-content .join {
    margin-top: 20px !important;
    margin-bottom: 18px !important;
  }

  .case .ant-tabs>.ant-tabs-nav {
    width: 320px !important;
  }

  .case .ant-tabs-tab-btn {
    font-size: 20px !important;
  }

  .case .case-content p {
    margin: 0 !important;
  }

  .ecological .ecological-content .ecological-right {
    width: 580px !important;
  }

  .ecological .ecological-content .ecological-right .ecological-right-container {
    margin: auto !important;
    left: 0 !important;
    right: 0 !important;
  }

  .data-problem .problem-container .problem-list .problem-item:hover,
  .data-collaboration .data-problem .problem-container .problem-list .problem-item:hover {
    background-position-x: center !important;
  }

  .data-problem .problem-container .problem-list .problem-item {
    width: 420px !important;
  }

  .data-collaboration .data-problem .problem-container .problem-list .problem-item {
    width: 100% !important;
  }

  .data-scene .scene-container {
    height: 600px !important;
  }

  .data-scene .scene-container img {
    width: 720px !important;
    height: auto !important;
  }

  .data-scene .scene-container div {
    width: 720px !important;

  }

  .data-scene .scene-container div span {
    margin-right: 70px !important;
  }

  .data-scene .scene-container div p {
    padding-left: 495px !important;
    padding-right: 40px !important;
  }

  .data-health .data-problem .problem-container .problem-list .problem-item {
    width: 280px !important;
    padding: 0 15px !important;
    text-align: justify !important;
  }

  .ecological-cooperation .cooperation-banner,
  .cooperationBook .cooperationBook-banner {
    background: url("./assets/img/ecologicalcooperation-banner.png");
    background-repeat: no-repeat;
    background-position-x: center;
  }

  .ecological-cooperation .cooperation-banner .cooperation-banner-img,
  .cooperationBook .cooperationBook-banner .cooperation-banner-img {
    display: none;
  }

  .ecological-cooperation .cooperation-banner .container p {
    padding: 0 80px !important;
  }

  .ecological-cooperation .cooperation-process .process-container div::before {
    display: none !important;
  }

  .ecological-cooperation .cooperation-equities .equities-container div {
    width: 302px !important;
    height: 245px !important;
    padding: 30px 16px 0 16px !important;
  }

  .cooperationBook .cooperationBook-banner .cooperationBook-banner-content p {
    padding: 0 80px !important;
  }

  .about .about-introduce .introduce-content div p {
    width: 530px !important;
  }

  .about .about-contact .contact-container .line-title div {
    width: 760px !important;
  }

  .footer {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
}

@media all and (min-width:980px) and (max-width:1279px) {

  .head .h-container,
  .product,
  .function .func-container,
  .case,
  .experience,
  .ecological .ecological-content,
  .experience-enter,
  .cooperation,
  .data-problem,
  .data-worth,
  .data-scene,
  .ecological-cooperation .cooperation-type,
  .ecological-cooperation .cooperation-process,
  .ecological-cooperation .cooperation-equities,
  .cooperationBook .cooperationBook-container,
  .document .document-container,
  .about .about-introduce,
  .about .about-contact {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .data-banner .data-banner-container,
  .ecological-cooperation .cooperation-banner .container,
  .cooperationBook .cooperationBook-banner .cooperationBook-banner-content,
  .about .about-banner .about-banner-content,
  .banner .banner-content {
    left: 50px !important;
    right: 50px !important;
  }

  .banner .banner-content,
  .about .about-banner .about-banner-content {
    bottom: 15px !important;
  }

  .banner .banner-content .banner-title {
    font-size: 28px !important;
  }

  .banner .banner-content .banner-tips {
    font-size: 12px !important;
  }

  .banner .banner-content .join {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .case .ant-tabs>.ant-tabs-nav {
    width: 270px !important;
  }

  .case .ant-tabs-tab-btn {
    font-size: 18px !important;
  }

  .case .case-content .case-btn span {
    margin-top: 10px !important;
  }

  .ecological .ecological-content .ecological-right {
    width: 540px !important;
  }

  .ecological .ecological-content .ecological-right .ecological-right-container {
    left: 0 !important;
    right: 0 !important;
    margin: auto;
  }

  .data-problem .problem-container .problem-list .problem-item {
    width: 390px !important;
  }

  .data-problem .problem-container .problem-list .problem-item:hover {
    background-position-x: center !important;
  }

  .data-scene .scene-container img {
    width: 690px !important;
    height: auto !important;
  }

  .data-scene .scene-container div {
    width: 700px !important;
    height: 500px !important;
  }

  .data-scene .scene-container div span {
    margin: 98px 35px 15px 445px !important;
  }

  .data-scene .scene-container div p {
    padding: 0px 35px 201px 510px !important;
  }

  .data-collaboration .data-problem .problem-container .problem-list .problem-item {
    width: 96% !important;
  }

  .data-health .data-problem .problem-container .problem-list .problem-item,
  .data-supplyChain .data-problem .problem-container .problem-list .problem-item {
    width: 290px !important;
  }

  .ecological-cooperation .cooperation-banner,
  .cooperationBook .cooperationBook-banner {
    background: url("./assets/img/ecologicalcooperation-banner.png");
    background-position-x: center;
  }

  .ecological-cooperation .cooperation-banner .cooperation-banner-img,
  .cooperationBook .cooperationBook-banner .cooperation-banner-img {
    display: none !important;
  }

  .ecological-cooperation .cooperation-banner .container p,
  .cooperationBook .cooperationBook-banner .cooperationBook-banner-content p {
    padding: 0 55px !important;
  }

  .ecological-cooperation .cooperation-process .process-container div::before {
    display: none !important;
  }

  .ecological-cooperation .cooperation-equities .equities-container div {
    width: auto !important;
    height: auto !important;
    padding: 40px 10px 0 10px !important;
  }

  .about .about-introduce .introduce-content div p {
    width: 510px !important;
  }

  .about .about-introduce .introduce-content div img {
    width: 360px !important;
    height: auto !important;
  }

  .about .about-contact .contact-container .line-title div {
    width: 740px !important;
    margin-left: 60px !important;
  }

  .footer {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}

/* 移动端 */


@media all and (max-width:979px) {
  .h-container {
    display: none !important;
  }
}